import fileManager from './component/fileManager';
import { loggerViewerLocaleEn } from '@/components/tools/logger-viewer/locales';
import logger from '@/locales/en-US/component/logger';
import { useLocale } from '@/hooks/useLocale';

export default {
  // Common
  'component.refresh': 'Refresh',
  'component.file-already-attached': ' File {label} is already attached in this row',
  'component.file-not-found': 'File not found',
  'component.to-detach-file': 'Detach file?',
  'component.to-attach': 'Attach',
  'component.rename': 'Rename',
  'component.file-name': 'File name',
  'component.size': 'Size',
  'component.directories': 'Directories',
  'component.path': 'Path',
  'component.enter-a-fractional-number': 'Enter a decimal number',
  'component.confirm-delete': 'Are you sure you want to delete',
  'component.confirm-delete-with-filler': 'Are you sure you want to delete {title}?',
  'component.to-block': 'Block',
  'component.to-unblock': 'Unblock',
  'component.read-only': 'Read only',
  'component.are-you-sure-delete-this-panel': 'Are you sure delete this panel?',
  'component.autoheight': 'Autoheight',
  'component.click-action': 'Click action',
  'component.enable': 'Enable',
  'component.tagSelect.expand': 'Expand',
  'component.tagSelect.collapse': 'Collapse',
  'component.tagSelect.all': 'All',
  'component.logout': 'Logout',
  'component.save': 'Save',
  'component.clear': 'Clear',
  'component.test': 'Test',
  'component.login': 'Login',
  'component.username': 'Username',
  'component.password': 'Password',
  'component.create': 'Create',
  'component.sync': 'Synchronization',
  'component.delete': 'Delete',
  'component.move': 'Move',
  'component.format': 'Format',
  'component.upload': 'Upload',
  'component.download': 'Download',
  'component.reset': 'Reset',
  'component.internalUrl': 'Address (internal)',
  'component.externalUrl': 'Address (external)',
  'component.finish': 'Finish',
  'component.enabled': 'Enabled',
  'component.disabled': 'Disabled',
  'component.not-available': 'Sorry, this page is not available to you',
  'component.home': 'Back Home',
  'component.on': 'On',
  'component.off': 'Off',
  'component.add': 'Add',
  'component.edit': 'Edit',
  'component.addition': 'Addition',
  'component.updating': 'Updating',
  'component.deleting': 'Deletion',
  'component.renaming': 'Renaming',
  'component.uploading': 'Upload',
  'component.downloading': 'Download',
  'component.confirm-delete-tool-title': 'Are you sure delete?',
  'component.confirm-delete-menu-title': 'Are you sure delete menu ',
  'component.confirm-delete-menu-group': 'Are you sure delete group',
  'component.group-has-been-added': 'This group has already been added!',
  'component.confirm-create-menu-title': 'Create menu?',
  'component.yes': 'Yes',
  'component.no': 'No',
  'component.cancel': 'Cancel',
  'component.profile': 'Profile',
  'component.label.first-name': 'First name',
  'component.label.last-name': 'Last name',
  'component.label.email': 'E-mail',
  'component.placeholder.first-name': '(Example: John)',
  'component.placeholder.last-name': 'Example: Smith',
  'component.placeholder.email': 'Example: login@gmail.com',
  'component.roles': 'Roles',
  'component.groups': 'Groups',
  'component.tools': 'tools',
  'component.name_filer': 'Name filer',
  'component.type_filer': 'Type filter',
  'component.all': 'All',
  'component.show_disabled': 'Show disabled',
  'component.preview': 'Preview',
  'component.name': 'Name',
  'component.description': 'Description',
  'component.label': 'Label',
  'component.type': 'Type',
  'component.menu': 'Menu',
  'component.folder': 'Folder',
  'component.title.settings': 'Settings',
  'component.title.add_tool': 'Add tool',
  'component.theme': 'Theme',
  'component.current': 'Current',
  'component.light': 'Light',
  'component.dark': 'Dark',
  'component.medium': 'Medium',
  'component.full': 'Full',
  'component.kiosk': 'Kiosk',
  'component.kiosk_tv': 'Kiosk TV',
  'component.view': 'View',
  'component.client': 'Client',
  'component.client_tooltip': 'Client settings',
  'component.server': 'Server',
  'component.server_tooltip': 'Server settings',
  'component.loading': 'Loading...',
  'component.version': 'Version',
  'component.default': 'Default',
  'component.title.confirm.share.enabled': 'Are you sure set property to enable?',
  'component.title.confirm.share.disabled': 'Are you sure set property to disable?',
  'component.error.tool-unsupported': 'Tool unsupported',
  'component.example': 'Example',
  'component.error': 'Error',
  'component.endpoint': 'Endpoint',
  'component.report': 'Report',
  'component.panel': 'Panel',
  'component.dashboard': 'Dashboard',
  'component.panel_on_click': 'Panel onClick',
  'component.constructor': 'Constructor',
  'component.variables': 'Variables',
  'component.fill': 'Fill',
  'component.range': 'Range',
  'component.select_range': 'Select range',
  'component.range_left_border': 'range.left border',
  'component.range_right_border': 'range.right border',
  'component.visible': 'Visible',
  'component.use_global_property': 'Use global property',
  'component.default_value': 'Default value',
  'component.presets': 'Presets',
  'component.templates': 'Templates',
  'component.functions': 'Functions',
  'component.Last_5_minutes': 'Last 5 minutes',
  'component.Last_15_minutes': 'Last 15 minutes',
  'component.Last_30_minutes': 'Last 30 minutes',
  'component.Last_1_hour': 'Last 1 hour',
  'component.Last_3_hours': 'Last 3 hour',
  'component.Last_6_hours': 'Last 6 hours',
  'component.Last_12_hours': 'Last 12 hours',
  'component.Last_24_hours': 'Last 24 hours',
  'component.Last_2_days': 'Last 2 days',
  'component.Last_7_days': 'Last 7 days',
  'component.Last_30_days': 'Last 30 days',
  'component.Last_90_days': 'Last 90 days',
  'component.Last_6_months': 'Last 6 months',
  'component.Last_1_year': 'Last 1 year',
  'component.Last_2_years': 'Last 2 years',
  'component.Last_5_years': 'Last 5 years',
  'component.Current_day': 'Current day',
  'component.Current_week': 'Current week',
  'component.Current_month': 'Current month',
  'component.Current_quarter': 'Current quarter',
  'component.Current_year': 'Current year',
  'component.Yesterday': 'Yesterday',
  'component.Last_week': 'Last week',
  'component.Last_month': 'Last month',
  'component.Last_quarter': 'Last quarter',
  'component.Last_year': 'Last year',
  'component.event': 'Event',
  'component.events': 'Events',
  'component.onClick': 'onClick',
  'component.action': 'Action',
  'component.sort': 'Sort',
  'component.service_unavailable': 'Service unavailable',
  'component.show': 'Show',
  'component.hide': 'Hide',
  'component.attributes': 'Attributes',
  'component.actions': 'Actions',
  'component.state': 'State',
  'component.multiple_values': 'Multiple values',
  'component.include': 'Include',
  'component.select_all': 'Select all',
  'component.values_separated_by_comma': 'Values separated by comma',
  'component.decorate': 'Decorate',
  'component.logo': 'Logo',
  'component.russian': 'Russian',
  'component.english': 'English',
  'component.kazakh': 'Kazakh',
  'component.deutsch': 'Deutsch',
  'component.style': 'Style',
  'component.visibility': 'Visibility',
  'component.select-range': 'Select range',
  'component.absolute': 'Absolute',
  'component.relative': 'Relative',
  'component.from': 'of',
  'component.change-value': 'Change value',
  'component.applicationName': 'Application name',
  'component.source': 'Source',
  'component.values': 'Values',
  'component.multi-values': 'Multiple values',
  'component.include-select-all': 'Enable [All]',
  'component.query': 'Query',
  'component.custom': 'Custom',
  'component.value': 'Value',
  'component.constant': 'Constant',
  'component.link': 'Link',
  'component.type-value': 'Type value',
  'component.input-variable-name': 'Input variable name!',
  'component.input-rule-name': 'Input rule name!',
  'component.name-already-exists': 'This name already exists!',
  'component.date-output-format': 'Output format',
  'component.date-output-accuracy': 'Input precision',
  'component.second': 'second',
  'component.minute': 'minute',
  'component.hour': 'hour',
  'component.day': 'day',
  'component.week': 'week',
  'component.month': 'month',
  'component.quarter': 'quarter',
  'component.year': 'year',
  'component.invalid-variable-value': 'invalid variable value',
  'component.change': 'Change',
  'component.unpin': 'Detach',
  'component.removed': 'Deleted',
  'component.not-removed': 'Not Removed',
  'component.added': 'Added',
  'component.not-added': 'Not Added',
  'component.changed': 'Modified',
  'component.not-changed': 'Not changed',
  'component.user-created': 'user added',
  'component.user-updated': 'user updated',
  'component.date-updated': 'date updated',
  'component.date-created': 'date added',
  'component.conditional-formatting': 'Conditional formatting',
  'component.rule': 'Rule',
  'component.cell': 'Cell',
  'component.please-select': 'Please select',
  'component.formatter': 'Formatter',
  'component.font-color': 'Font color',
  'component.background-color': 'Background color',
  'component.font': 'Font',
  'component.font-size': 'Font size',
  'component.condition': 'Condition',
  'component.localization.support': 'Localization support',
  'component.not-path-for-panel': 'No path for the panel',
  'component.access': 'Access',
  'component.info': 'Info',
  'component.system-info': 'System info',
  'component.width': 'Width',
  'component.empty': 'empty',
  'component.file': 'File',
  'component.not-found': 'not found',
  'component.clone': 'Clone',
  'component.row-number': 'Number row',
  'component.invalid-value': 'Invalid value entered',

  // Grafana
  'component.grafana.internal': 'Server address(internal)',
  'component.grafana.external': 'External link',
  'component.grafana.basic-api-uri': 'Basic api uri',
  'component.grafana.api-key': 'Api key',
  'component.footer.allRightReserved': 'All rights reserved',
  'component.navigation': 'Navigation',
  'component.top': 'Top',
  'component.left': 'Left',
  'component.mix': 'Mix',
  'component.grafana.remove-panel': 'Удалить панель',
  'component.grafana.columns-count': 'Columns',
  'component.grafana.row-height': 'Row height',
  'component.grafana.user-transformation': 'Change panels in Viewer',
  'component.grafana.stretch-to-window-height': 'Stretch to window height',
  'component.grafana.edit-panel': 'Edit panel',
  'component.grafana.panel-group': 'Dashboard',
  'component.grafana.use-board': 'Use Board',
  'component.grafana.system-info-error': 'Failed to get service information',
  'component.grafana.add-panel': 'Create',

  // System db
  'component.system-db.hostname': 'hostname',
  'component.system-db.port': 'port',
  'component.system-db.name': 'database name',
  'component.system-db.username': 'username',
  'component.system-db.driver': 'driver',
  'component.system-db.want-to-delete': 'This operation is irreversible. Are you sure you want to delete the system database?',
  'component.system-db.want-to-format': 'This operation is irreversible. Are you sure you want to format the system database?',

  // Sso
  'component.sso.realm': 'Realm',
  'component.sso.clientId': 'Client ID',
  'component.sso.port': 'Port',
  'component.sso.secret': 'Secret',
  'component.sso.system-info-error': 'Failed to get service information',

  // Rs
  'component.rs.system-info-error': 'Failed to get service information',

  // User-profile
  'component.profile.login-name': 'Login name',
  'component.profile.new-password': 'New password',
  'component.profile.new-password-repeat': 'New password [repeat]',
  'component.profile.change-password': 'Change Password',

  // Hasura
  'component.hasura.service-address': 'Service address',
  'component.hasura.server': 'Server',
  'component.hasura.server-settings': 'Server settings',
  'component.hasura.select.label': 'Table',
  'component.hasura.select.placeholder': 'Select table',
  'component.hasura.fields': 'Fields',
  'component.hasura.table.label': 'Label',
  'component.hasura.table.type': 'Type',
  'component.hasura.table.attributes': 'Attributes',
  'component.hasura.table.action': 'Action',
  'component.hasura.field': 'Field',
  'component.hasura.no-description': 'No description',
  'component.hasura.fields-not-configured': 'Fields not configured',
  'component.hasura.form-constructor': 'Constructor form input/output',
  'component.hasura.min-height': 'Min height',
  'component.hasura.min-width': 'Min width',
  'component.hasura.max-height': 'Max height',
  'component.hasura.max-width': 'Max width',
  'component.hasura.columns-quantity': 'Columns quantity',
  'component.hasura.missing-primary-key': 'Missing primary key',
  'component.hasura.table-settings': 'Table settings',
  'component.hasura.decorate': 'Decorate',
  'component.hasura.fitAndFill': 'Lines to full screen width',
  'component.hasura.rowHeight': 'Row height',
  'component.hasura.fix': 'Fixator',
  'component.hasura.rowsTop': 'Top',
  'component.hasura.colsLeft': 'Left',
  'component.hasura.colsRight': 'Right',
  'component.hasura.export': 'Export',
  'component.hasura.import': 'Import',
  'component.hasura.output': 'Output',
  'component.hasura.relation': 'Relation',
  'component.hasura.restrictions': 'Restrictions',
  'component.token': 'Token',
  'component.hasura.content-type': 'Content Type',
  'component.hasura.no-value': 'No value',
  'component.hasura.source': 'Source',
  'component.hasura.matrix': 'Matrix',
  'component.hasura.axisY': 'Y axis',
  'component.hasura.axisX': 'X axis',
  'component.hasura.matrix-field': 'Matrix field',
  'component.hasura.nesting-level': 'Nesting level',
  'component.hasura.pagination': 'Pagination',
  'component.hasura.rows-per-page': 'row/page',
  'component.hasura.conditions': 'Conditions',
  'component.hasura.sorting-type': 'Sorting type',
  'component.hasura.soft': 'Soft',
  'component.hasura.invalid-json-format': 'Invalid json format',
  'component.hasura.invalid-xml-format': 'Invalid xml format',
  'component.hasura.adding-group': 'Adding a group',
  'component.hasura.changing-group': 'Changing a group',
  'component.hasura.permissions': 'Permissions',

  // Global
  'component.global.name-restriction': 'Text no more than 50 characters',
  'component.global.desc-restriction': 'Text no more than 255 characters',
  'component.global.language-selection': 'Language',
  'component.global.default-value': 'Default value',
  'component.global.languages': 'List',

  // AuthTools
  'component.auth.users': 'Users',
  'component.auth.disabled-users': 'Disabled users',
  'component.auth.groups': 'Groups',
  'component.auth.disabled-tools': 'Disabled tools',
  'component.auth.disabled': 'Disabled',
  'component.auth.enabled': 'Enabled',
  'component.auth.inherited': 'Inherited',
  'component.auth.default': 'Default',
  'component.auth.name-filter': 'Name filter',
  'component.auth.tools-filter': 'tools filter',

  // Navigation
  'component.navigation.change-directory': 'Change directory',
  'component.navigation.change-tool': 'Change tool',

  // Crud
  'component.function.pagination-top': 'Top',
  'component.function.pagination-bottom': 'Bottom',
  'component.ui.CrudFormConstructor.tooltipColumnsQuantity':
    'Free up the space used by the elements first, than decrease the value',

  // report
  'component.report.path-not-set': 'The path for the report is not set!',
  'component.report.time-functions': 'Time functions',

  // DataInput
  'component.data-input.choose': 'choose',
  'component.data-input.download': 'Download',
  'component.data-input.upload': 'upload',
  'component.data-input.new-file': 'New file',
  'component.data-input.date-of-download': 'date of download',
  'component.data-input.name-of-uploaded-file': 'Name of the uploaded file',
  'component.data-input.no-file-selected': 'No file selected',
  'component.data-input.delete-the-selected-file': 'Delete the selected file?',
  'component.data-input.enter-file-name': 'Enter file name',

  // DataInputs placeholders
  'component.data.input.int': 'Example: 123',
  'component.data.input.bigint': 'Example: 123',
  'component.data.input.string': 'Example: Symbols',
  'component.data.input.xml': 'Example: Symbols',
  'component.data.input.float8': 'Example: 123.321',
  'component.data.input._float8': 'Example: 123.321',
  'component.data.input._int4': 'Example: 123',
  'component.data.input.timestamp': 'Select date and time',
  'component.data.input.timestamptz': 'Select date and time',
  'component.data.input.date': 'Select the date',
  'component.data.input.bpchar': 'Enter text',
  'component.data.input.object': 'Choose from the list',
  'component.data.input.time': 'Select time',
  'component.data.input._varchar': 'Example: abc',
  'component.data.input.uuid': 'Enter uuid',
  'component.data.input.json': 'Example: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.jsonb': 'Example: { "fileName": "my file name", "fileSize": 1024 }',
  'component.data.input.file-selection': 'File selection',

  // ArrayEditor
  'component.array-editor.delete-the-selected-row': 'Are you sure you want to delete this row?',

  // IOCoordinates
  'component.io-coordinates.map': 'Map',

  // IOJsonbSys
  'component.io-jsonb-sys.history': 'History',
  'component.io-jsonb-sys.user': 'User',
  'component.io-jsonb-sys.timestamp': 'Timestamp',
  'component.io-jsonb-sys.adding': 'Adding',
  'component.io-jsonb-sys.editing': 'Editing',
  'component.io-jsonb-sys.deleting': 'Deleting',

  // ActionButton
  'action.button-create': 'Create',
  'action.button-save': 'Save',
  'action.button-cancel': 'Cancel',
  'action.button-delete': 'Delete',
  'action.button-clone': 'Clone',
  'action.button-import': 'Import',
  'action.button-export': 'Export',
  'action.button-download': 'Download',
  'action.button-upload': 'Upload',
  'action.button-reset': 'Reset',
  'action.button-play': 'Launch',
  'action.button-login': 'Login',
  'action.button-fill': 'Fill',
  'action.button-update': 'Update',
  'action.button-edit': 'Edit',
  'action.button-remove': 'Remove',

  // FieldFilter
  'modal.field-filter-modal.filter': 'Filter',
  'component.field-filter.filter': 'Filter',
  'component.field-filter.type': 'Type',
  'component.field-filter.off': 'Off',
  'component.field-filter.eq': 'Equal',
  'component.field-filter.neq': 'Not equal',
  'component.field-filter.gt': 'Greater than',
  'component.field-filter.gte': 'Greater than or equal to',
  'component.field-filter.in': 'In',
  'component.field-filter.nin': 'Not in',
  'component.field-filter.is_null': 'Is null',
  'component.field-filter.lt': 'Less than',
  'component.field-filter.lte': 'Less than or equal to',
  'component.field-filter.like': 'Like (content-based, case-sensitive)',
  'component.field-filter.nlike': 'Not like (content-based, case-sensitive)',
  'component.field-filter.ilike': 'Like (content-based, case-insensitive)',
  'component.field-filter.nilike': 'Not like (content-based, case-insensitive)',
  'component.field-filter.similar': 'Similar (content-based)',
  'component.field-filter.nsimilar': 'Not similar (content-based)',
  'component.field-filter.regex': 'Regular expression (case-sensitive)',
  'component.field-filter.nregex': 'Not regular expression (case-sensitive)',
  'component.field-filter.iregex': 'Regular expression (case-insensitive)',
  'component.field-filter.niregex': 'Not regular expression (case-insensitive)',
  'component.field-filter.on': 'On',
  'component.field-filter.value': 'Value',

  // editor/application
  'page.settings-application.general': 'General',
  'page.settings-application.text': 'Text',
  'page.settings-application.logoMesone': 'Logo MESone',
  'page.settings-application.footer': 'Footer',

  // User/profile
  'page.user-profile.user-name': 'User name',
  'page.user-profile.admin': 'Admin',
  'page.user-profile.first-name': 'First name',
  'page.user-profile.placeholder-name': 'Ivan',
  'page.user-profile.last-name': 'Last name',
  'page.user-profile.default-tool': 'Default tool',
  'page.user-profile.tools': 'Tools',
  'page.user-profile.placeholder-input-search': 'Input search text',
  'page.user-profile.groups': 'Groups',

  // Instance editor
  'component.instance-editor.image': 'Image',
  'component.instance-editor.text': 'Text',
  'component.instance-editor.main': 'Main',
  'component.instance-editor.additional': 'Additional',

  // Switcher
  'component.switcher.enabled': 'Enabled',

  // Select users groups
  'select-users-groups.users': 'Users',

  // Tools navigator
  'component.tools-navigator.showTypes': 'Display types',
  'component.tools-navigator.showBlocked': 'Display unavailable',

  // Tool settings common
  'component.tool-settings-common.permissions': 'Permissions',
  'component.tool-settings-common.name': 'Name',
  'component.tool-settings-common.type': 'Type',
  'component.tool-settings-common.label': 'Shortcut',
  'component.tool-settings-common.description': 'Description',
  'component.tool-settings-common.info': 'Info',
  'component.tool-settings-common.sureDelete': 'Are you sure you want to delete the tool {name}?',

  // Table settings
  'component.table-settings.pagination': 'Pagination',
  'component.table-settings.rows': 'rows/page',
  'component.table-settings.top': 'Top',
  'component.table-settings.bottom': 'Bottom',
  'component.table-settings.fillAndFit': 'Stretch columns to fit the width',
  'component.table-settings.rowHeight': 'Row height',
  'component.table-settings.fontSize': 'Font size',
  'component.table-settings.fix': 'Fix',
  'component.table-settings.left': 'Left',
  'component.table-settings.right': 'Right',
  'component.table-settings.export': 'Export',
  'component.table-settings.import': 'Import',

  // Range editor
  'component.range-editor.range': 'Range',
  'component.range-editor.visibility': 'Visibility',
  'component.range-editor.useGlobalProperty': 'Use global value',
  'component.range-editor.presets': 'Presets',
  'component.range-editor.defaultValue': 'Default value',
  'component.range-editor.absolute': 'Absolute',
  'component.range-editor.relative': 'Relative',

  // Range viewer
  'component.range-preset.Last_5_minutes': 'last 5 min.',
  'component.range-preset.Last_15_minutes': 'last 15 min.',
  'component.range-preset.Last_30_minutes': 'last half hour',
  'component.range-preset.Last_1_hour': 'last hour',
  'component.range-preset.Last_3_hours': 'last 3 hrs',
  'component.range-preset.Last_6_hours': 'last 6 hrs',
  'component.range-preset.Last_12_hours': 'last half day',
  'component.range-preset.Last_24_hours': 'last day',
  'component.range-preset.Last_2_days': 'last 2 days',
  'component.range-preset.Last_7_days': 'last week',
  'component.range-preset.Last_30_days': 'last month',
  'component.range-preset.Last_90_days': 'last quarter',
  'component.range-preset.Last_6_months': 'last half year',
  'component.range-preset.Last_1_year': 'last year',
  'component.range-preset.Last_2_years': 'last 2 years',
  'component.range-preset.Last_5_years': 'last 5 years',
  'component.range-preset.Current_day': 'curr. day',
  'component.range-preset.Current_week': 'curr. week',
  'component.range-preset.Current_month': 'curr. month',
  'component.range-preset.Current_quarter': 'curr. quarter',
  'component.range-preset.Current_year': 'curr. year',
  'component.range-preset.Yesterday': 'yesterday',
  'component.range-preset.Last_week': 'last week',
  'component.range-preset.Last_month': 'last month',
  'component.range-preset.Last_quarter': 'last quarter',
  'component.range-preset.Last_year': 'last year',

  // Updater editor
  'component.updater-editor.range': 'Update',
  'component.updater-editor.visibility': 'Visibility',
  'component.updater-editor.useGlobalValue': 'Use global value',
  'component.updater-editor.defaultValue': 'Default value',

  'component.updater-preset.Off': 'Off',
  'component.updater-preset.5s': '5 sec.',
  'component.updater-preset.10s': '10 sec.',
  'component.updater-preset.30s': '30 sec.',
  'component.updater-preset.1m': '1 min.',
  'component.updater-preset.5m': '5 min.',
  'component.updater-preset.15m': '15 min.',
  'component.updater-preset.30m': '30 min.',
  'component.updater-preset.1h': '1 hr.',
  'component.updater-preset.2h': '2 hrs.',
  'component.updater-preset.1d': '1 day',

  // List extended
  'component.list-extended.selectAll': 'Select all',
  'component.list-extended.all': 'All',

  // Data input int
  'component.data-input-int.placeholder': 'Enter an integer',

  // Data input settings
  'component.data-input-settings.contentType': 'Content type',
  'component.data-input-settings.systemInformation': 'System information',
  'component.data-input-settings.dateInputFormat': 'Date input format',
  'component.data-input-settings.timeInputFormat': 'Time input format',
  'component.data-input-settings.dateTimeInputFormat': 'Date and time input format',
  'component.data-input-settings.editor': 'Editor',
  'component.data-input-settings.files': 'Files',
  'component.data-input-settings.coordinates': 'Coordinates',
  'component.data-input-settings.week': 'Week',
  'component.data-input-settings.month': 'Month',
  'component.data-input-settings.quarter': 'Quarter',
  'component.data-input-settings.year': 'Year',
  'component.data-input-jpg-base64.upload': 'Upload',

  // Variable custom editor
  'component.variable.custom.editor': 'Set of values',
  'component.variable-custom-editor.preview-default-value': 'Preview/Default value',

  // Variable value editor
  'component.variable-value-editor.dataType': 'Data type',
  'component.variable-value-editor.boolean': 'Boolean',
  'component.variable-value-editor.int': 'Integer',
  'component.variable-value-editor.float4': 'Floating point',
  'component.variable-value-editor.float': 'Floating point',
  'component.variable-value-editor.bpchar': 'String',
  'component.variable-value-editor.date': 'Date',
  'component.variable-value-editor.time': 'Time',
  'component.variable-value-editor.timestamp': 'Timestamp',
  'component.variable-value-editor.bool': 'Boolean array',
  'component.variable-value-editor.int4': 'Integer array',
  'component.variable-value-editor.float8': 'Floating point array',
  'component.variable-value-editor.varchar': 'String array',
  'component.variable-value-editor.uuid': 'Identifier',

  // Variable query editor
  'component.list-extended.templates': 'Templates',
  'component.list-extended.basic': 'Basic',
  'component.list-extended.textValueAliases': 'Text/Value aliases',
  'component.list-extended.variables': 'Variables',
  'component.list-extended.query': 'Query',
  'component.list-extended.previewDefaultValue': 'Preview/Default value',

  // Row control
  'component.row-control.edit': 'Edit',
  'component.row-control.move': 'Move',
  'component.row-control.delete': 'Delete',

  // Row mover
  'component.row-mover.move': 'Move',
  'component.row-mover.rowNumber': 'Row number',

  // Variables editor
  'component.variables-editor.name': 'Name',
  'component.variables-editor.label': 'Label',
  'component.variables-editor.type': 'Type',
  'component.variables-editor.attributes': 'Attributes',
  'component.variables-editor.actions': 'Actions',
  'component.variables-editor.confirmDelete': 'Are you sure you want to delete the {name} variable?',
  'component.variables-editor.var-value': 'Variable',
  'component.variables-editor.var-custom': 'Static list',
  'component.variables-editor.var-query': 'Dynamic list',
  'component.variables-editor.dataTransfer': 'Data transfer',
  'component.variables-editor.Auto': 'Auto',
  'component.variables-editor.Manual': 'Manual',

  // Variable editor
  'component.variable-editor.add': 'Add',
  'component.variable-editor.edit': 'Edit',
  'component.variable-editor.readOnly': 'Read-only',
  'component.variable-editor.visible': 'Visible',
  'component.variable-editor.name': 'Name',
  'component.variable-editor.enterVariableName': 'Enter variable name',
  'component.variable-editor.label': 'Label',
  'component.variable-editor.description': 'Description',
  'component.variable-editor.type': 'Type',
  'component.variable-editor.width': 'Width',
  'component.variable-editor.invalidName': 'Invalid name',

  // Table fields
  'component.table-fields.name': 'Name',
  'component.table-fields.label': 'Label',
  'component.table-fields.type': 'Type',
  'component.table-fields.attributes': 'Attributes',
  'component.table-fields.actions': 'Actions',
  'component.table-fields.totalItems': 'Total {i} items',

  // Table form editor
  'component.table-form-editor.preview': 'Preview',
  'component.table-form-editor.min': 'Min.',
  'component.table-form-editor.max': 'Max.',
  'component.table-form-editor.minHeight': 'Min. height',
  'component.table-form-editor.maxHeight': 'Max. height',
  'component.table-form-editor.width': 'Width',
  'component.table-form-editor.minWidth': 'Min. width',
  'component.table-form-editor.maxWidth': 'Max. width',
  'component.table-form-editor.columns': 'Columns',
  'component.table-form-editor.minimumReached': 'Minimum reached. First free up space, then change the number of columns.',

  // Field sorting editor
  'modal.field-sorting-editor.edit': 'Edit',
  'modal.field-sorting-editor.field': 'Field',
  'modal.field-sorting-editor.sortingRule': 'Sorting rule',
  'modal.field-sorting-editor.noSorting': 'No sorting',
  'modal.field-sorting-editor.asc': 'Ascending',
  'modal.field-sorting-editor.asc_nulls_first': 'Ascending null first',
  'modal.field-sorting-editor.asc_nulls_last': 'Ascending null last',
  'modal.field-sorting-editor.desc': 'Descending',
  'modal.field-sorting-editor.desc_nulls_first': 'Descending null first',
  'modal.field-sorting-editor.desc_nulls_last': 'Descending null last',

  // Conditional formatting
  'component.conditional-formatting.confirmDelete': 'Are you sure you want to delete the {name} rule?',
  'component.conditional-formatting.name': 'Name',
  'component.conditional-formatting.description': 'Description',
  'component.conditional-formatting.attributes': 'Attributes',
  'component.conditional-formatting.actions': 'Actions',
  'component.conditional-formatting.totalItems': 'Total {i} items',

  // Conditional Formatting Editor
  'component.conditional-format-editor.add': 'Add',
  'component.conditional-format-editor.edit': 'Edit',
  'component.conditional-format-editor.name': 'Name',
  'component.conditional-format-editor.enterRuleName': 'Enter rule name',
  'component.conditional-format-editor.description': 'Description',
  'component.conditional-format-editor.fields': 'Fields',
  'component.conditional-format-editor.allFields': 'All fields (whole row)',
  'component.conditional-format-editor.formatter': 'Formatter',
  'component.conditional-format-editor.fontColor': 'Font color',
  'component.conditional-format-editor.backgroundColor': 'Background color',
  'component.conditional-format-editor.font': 'Font',
  'component.conditional-format-editor.bold': 'Bold',
  'component.conditional-format-editor.italic': 'Italic',
  'component.conditional-format-editor.underline': 'Underline',
  'component.conditional-format-editor.conditions': 'Conditions',

  // Table source
  'component.table-source.table': 'Table',
  'component.table-source.selectTable': 'Select table',
  'component.table-source.description': 'Description',
  'component.table-source.options': 'Option',
  'component.table-source.main': 'Main',
  'component.table-source.aggregated': 'Aggregated',
  'component.table-source.withKeys': 'With keys',

  // Tools editor
  'component.tools-editor.source': 'Source',
  'component.tools-editor.permissions': 'Permissions',
  'component.tools-editor.fields': 'Fields',
  'component.tools-editor.groups': 'Groups',
  'component.tools-editor.tableSettings': 'Table settings',
  'component.tools-editor.preview': 'Preview',
  'component.tools-editor.formBuilder': 'Input/Output Form Builder',
  'component.tools-editor.timeFunctions': 'Time Functions',
  'component.tools-editor.variables': 'Variables',
  'component.tools-editor.conditions': 'Conditions',
  'component.tools-editor.sorting': 'Sorting',
  'component.tools-editor.conditionalFormatting': 'Conditional Formatting',
  'component.tools-editor.constructor': 'Constructor',
  'component.tools-editor.matrix': 'Matrix',
  'component.tools-editor.additionalFormats': 'Additional Formats',
  'component.tools-editor.localizationSupport': 'Localization Support',
  'component.tools-editor.args': 'Arguments',

  // Table permissions
  'component.table-permissions.add': 'Add',
  'component.table-permissions.update': 'Update',
  'component.table-permissions.delete': 'Delete',

  // Dashboard editor
  'component.dashboard-editor.add': 'Add',
  'component.dashboard-editor.edit': 'Edit',
  'component.dashboard-editor.name': 'Name',
  'component.dashboard-editor.selectPanelName': 'Select panel name',
  'component.dashboard-editor.localizationSupport': 'Localization Support',
  'component.dashboard-editor.view': 'View',
  'component.dashboard-editor.full': 'Full',
  'component.dashboard-editor.kiosk': 'Kiosk',
  'component.dashboard-editor.kioskTV': 'Kiosk TV',
  'component.dashboard-editor.theme': 'Theme',
  'component.dashboard-editor.current': 'Current',
  'component.dashboard-editor.light': 'Light',
  'component.dashboard-editor.dark': 'Dark',
  'component.dashboard-editor.openToolOnClick': 'Open tool on click',
  'component.dashboard-editor.selectTool': 'Select tool',

  // Dashboard designer
  'component.dashboard-designer.autoHeight': 'Auto height',
  'component.dashboard-designer.changePanelsInViewMode': 'Change panels in view mode',
  'component.dashboard-designer.rowHeight': 'Row height',
  'component.dashboard-designer.columns': 'Columns',

  // Dashboard panel
  'component.dashboard-panel.edit': 'Edit',
  'component.dashboard-panel.delete': 'Delete',
  'component.dashboard-panel.confirmationMessage': 'Are you sure you want to delete {i} panel?',

  // Settings users
  'pages.settings-users.staticInformation': 'Static information',
  'pages.settings-users.created': 'Created',
  'pages.settings-users.userName': 'User name',
  'pages.settings-users.editableInformation': 'Editable information',
  'pages.settings-users.name': 'Name',
  'pages.settings-users.surname': 'Surname',
  'pages.settings-users.changePassword': 'Change password',
  'pages.settings-users.newPassword': 'New password',
  'pages.settings-users.enterNewPasswordAgain': 'Enter new password again',
  'pages.settings-users.groups': 'Groups',
  'pages.settings-users.tools': 'Tools',
  'pages.settings-users.confirmDelete': 'Are you sure you want to delete user {i}?',

  // Settings groups
  'pages.settings-groups.confirmDelete': 'Are you sure you want to delete the group {i}?',
  'pages.settings-groups.users': 'Users',
  'pages.settings-groups.subGroup': 'Subgroup',

  // Settings services grafana
  'pages.settings-grafana-services.version': 'Version',
  'pages.settings-grafana-services.executionTime': 'Execution time',
  'pages.settings-grafana-services.description': 'This a description',
  'pages.settings-grafana-services.error': 'Error',
  'pages.settings-grafana-services.waiting': 'Waiting',

  // Field editor simple
  'component.field-editor-simple.edit': 'Edit',
  'component.field-editor-simple.name': 'Name',
  'component.field-editor-simple.type': 'Type',
  'component.field-editor-simple.description': 'Description',
  'component.field-editor-simple.label': 'Label',
  'component.field-editor-simple.parameters': 'Parameters',
  'component.field-editor-simple.keyField': 'Key field',
  'component.field-editor-simple.readOnly': 'Read only',
  'component.field-editor-simple.lazyLoading': 'Lazy loading',
  'component.field-editor-simple.quickView': 'Quick view',
  'component.field-editor-simple.quickEdit': 'Quick edit',
  'component.field-editor-simple.sorting': 'Sorting',
  'component.field-editor-simple.filter': 'Filter',
  'component.field-editor-simple.foreignKey': 'Foreign key',
  'component.field-editor-simple.selectForeignKey': 'Select foreign key',
  'component.field-editor-simple.formBuilder': 'Quick view / edit form builder',
  'component.field-editor-simple.inputOutputSettings': 'Input/output settings',
  'component.field-editor-simple.outputFormat': 'Output format',
  'component.field-editor-simple.quickAction': 'Quick Action',
  'component.field-editor-simple.disabled': 'Disabled',
  'component.field-editor-simple.view': 'View',
  'component.field-editor-simple.editing': 'Edit',

  // Field editor object
  'component.field-editor-object.edit': 'Edit',
  'component.field-editor-object.name': 'Name',
  'component.field-editor-object.type': 'Type',
  'component.field-editor-object.description': 'Description',
  'component.field-editor-object.label': 'Label',
  'component.field-editor-object.parameters': 'Parameters',
  'component.field-editor-object.readOnly': 'Read only',
  'component.field-editor-object.filter': 'Filter',
  'component.field-editor-object.complexAddition': 'Complex addition',
  'component.field-editor-object.groupFields': 'Group fields',
  'component.field-editor-object.complexAdditionFormBuilder': 'Complex addition form builder',
  'component.field-editor-object.conditions': 'Conditions',
  'component.field-editor-object.sorting': 'Sorting',

  // Field editor list
  'component.field-editor-list.edit': 'Edit',
  'component.field-editor-list.name': 'Name',
  'component.field-editor-list.type': 'Type',
  'component.field-editor-list.description': 'Description',
  'component.field-editor-list.label': 'Label',
  'component.field-editor-list.parameters': 'Parameters',
  'component.field-editor-list.readOnly': 'Read only',
  'component.field-editor-list.filter': 'Filter',
  'component.field-editor-list.complexAddition': 'Complex addition',
  'component.field-editor-list.viewerFormBuilder': 'Viewer form builder',
  'component.field-editor-list.complexAdditionFormBuilder': 'Complex addition form builder',
  'component.field-editor-list.conditions': 'Conditions',
  'component.field-editor-list.sorting': 'Sorting',

  // User creator
  'component.user-creator.add': 'Add',
  'component.user-creator.user-name': 'User name',
  'component.user-creator.enter-name': 'Enter name',
  'component.user-creator.invalidName': 'Invalid name',

  // Group creator
  'component.group-creator.add': 'Add',
  'component.group-creator.name': 'Name',
  'component.group-creator.enterName': 'Enter name',
  'component.group-creator.invalidName': 'Invalid name',

  // Table editor
  'component.table-editor.add': 'Add',
  'component.table-editor.edit': 'Edit',
  'component.table-editor.delete': 'Delete',

  // table/viewer
  'component.table-viewer.total': 'Total items',
  'component.table-viewer.sort': 'Sort',
  'component.table-viewer.noSort': 'No sorting',
  'component.table-viewer.ascending': 'Ascending',
  'component.table-viewer.ascendingEmptyBeginning': 'Ascending (empty at the beginning)',
  'component.table-viewer.ascendingEmptyEnd': 'Ascending (empty at the end)',
  'component.table-viewer.descending': 'Descending',
  'component.table-viewer.descendingEmptyBeginning': 'Descending (empty at the beginning)',
  'component.table-viewer.descendingEmptyEnd': 'Descending (empty at the end)',
  'component.table-viewer.filter': 'Filter',
  'component.table-viewer.tune': 'Tune',
  'component.table-viewer.clearFilter': 'Clear filter',
  'component.table-viewer.clearAll': 'Clear all',

  // table-output-settings
  'component.table-output-settings.outputFormat': 'Output Format',

  // onboarding-editor
  'component.onboarding-editor.name': 'Name',
  'component.onboarding-editor.label': 'Shortcut',
  'component.onboarding-editor.description': 'Description',
  'component.onboarding-editor.attributes': 'Attributes',
  'component.onboarding-editor.actions': 'Actions',
  'component.onboarding-editor.edit': 'Edit',
  'component.onboarding-editor.delete': 'Delete',
  'component.onboarding-editor.enabled': 'Enabled',
  'component.onboarding-editor.disabled': 'Disabled',
  'component.onboarding-editor.tour': 'Tour',
  'component.onboarding-editor.add': 'Add',
  'component.onboarding-editor.invalidName': 'Invalid name',
  'component.onboarding-editor.totalItems': 'Total {i} items',

  // table/conditions
  'component.table-conditions.noVar': 'No variable',
  'component.table-conditions.wrongVarType': 'Variable type does not match field type',
  'component.table-conditions.wrongConstType': 'Constant type does not match field type',
  'component.table-conditions.leftBorder': 'Left border',
  'component.table-conditions.rightBorder': 'Right border',

  // onboarding-step-editor
  'component.onboarding-step-editor.title': 'Title',
  'component.onboarding-step-editor.comment': 'Comment',
  'component.onboarding-step-editor.add': 'Add',
  'component.onboarding-step-editor.edit': 'Edit',

  // base-modal-window
  'component.base-modal-window.cancel': 'Cancel',

  // table-args
  'component.table-args.totalItems': 'Total {i} items',

  ...fileManager,
  ...logger,
  ...loggerViewerLocaleEn,
};
